import React from 'react';
import Logo1 from "../../assets/audio/images/logo185.png"
import { Link } from 'react-router-dom';
const Footer1 = () => {
    

    const handleLinkClick = () => {
      
      window.scrollTo(0, 0);
    };
  return (
    <>
      <footer>
                <div class="w-100 pt-110 black-layer pb-60 opc1 position-relative">
                    <div class="fixed-bg"style={{ backgroundImage: 'url(images1/sec-bg2.jpg)' }}></div>
                    <div class="container">
                        <div class="footer-data v3 w-100">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-lg-6">
                                    <div class="widget w-100">
                                    	<div class="logo"><h1 class="mb-0"><Link to="/" title="Home"><img class="img-fluid" src="assets/images/logo2.png" alt="Logo" srcset={Logo1} /></Link></h1></div>
                                    	{/* <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipisiuam, od mai consectetur Aliquam li onsectetur adipisiua odi um dolor sit consectetur adipisiuam.</p> */}
                                    </div>
                                    <div class="widget w-100">
                                        <h4 class="widget-title">Connect with social links.</h4>
                                        <div class="social-links3 d-flex flex-wrap w-100">
                                            <Link to="https://google.com/" title="Google Plus" ><i class="fab fa-google-plus-g"></i>google+</Link>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-sm-12 col-lg-6">
                                    <div class="widget w-100">
                                        <h4 class="widget-title">Sign up for our news</h4>
                                        <form class="newsletter-form w-100">
                                            <i class="far fa-envelope thm-clr"></i>
                                            <input type="email" placeholder="Enter your email address here" />
                                            <button class="thm-btn thm-bg" type="submit">SUBMIT<span></span><span></span><span></span><span></span></button>
                                        </form>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-5 col-sm-6 col-lg-5">
                                            <div class="widget w-100">
                                                <h4 class="widget-title">Get Involved</h4>
                                                <ul class="mb-0 list-unstyled w-100">
                                                    <li><Link onClick={handleLinkClick} to="/" title="">Home</Link></li>
                                                    <li><Link onClick={handleLinkClick} to="/hazrat" title="">Bayan</Link></li>
                                                    <li><Link onClick={handleLinkClick} to="/naat" title="">Naat</Link></li>
                                                    <li><Link onClick={handleLinkClick} to="/seerat" title="">Books</Link></li>
                                                    {/* <li><Link onClick={handleLinkClick} to="/blog" title="">Our Blog</Link></li> */}
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-md-5 col-sm-6 col-lg-5">
                                            <div class="widget w-100">
                                                <h4 class="widget-title">Useful links</h4>
                                                <ul class="mb-0 list-unstyled w-100">
                                                <li><Link onClick={handleLinkClick} to="/services" title="">Services</Link></li>
                                                <li><Link onClick={handleLinkClick} to="/about" title="">About</Link></li>
                                                    <li><Link onClick={handleLinkClick} to="/contact" title="">Contact Us</Link></li>
                                                    <li><Link onClick={handleLinkClick} to="/login" title="">Login</Link></li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
    </>
  );
};

export default Footer1;
