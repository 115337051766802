import React from 'react'
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Logo1 from "../../assets/audio/images/logo185.png"
import Logo2 from "../../assets/audio/images/logo1851.png"
import axios from 'axios';


const NavbarAllFiles = () => {
    const [sunriseTime, setSunriseTime] = useState('');
    const [sunsetTime, setSunsetTime] = useState('');
    const [preloaderVisible, setPreloaderVisible] = useState(true);
    useEffect(() => {
        const currentDate = new Date().toISOString().split('T')[0];
        const apiUrl = `https://api.sunrisesunset.io/json?lat=24.9331712&lng=67.1055872&date=${currentDate}`;
        console.log(currentDate);
        try {
          setPreloaderVisible(false);
          console.log("Seerat data fetched successfully");
        } catch (error) {
          setPreloaderVisible(false);
          console.error('Error fetching Seerat data:', error.message);
        }
        
        // Fetch sunrise and sunset times
        axios.get(apiUrl)
          .then(response => {
            const { sunrise, sunset } = response.data.results;
            setSunriseTime(sunrise);
            setSunsetTime(sunset);
          })
          .catch(error => {
            console.error('Error fetching sunrise and sunset data:', error);
          });
      }, []);
      const handleMenuBtnClick = () => {
        const menu = document.querySelector('.rsnp-mnu');
        menu.classList.add('slidein');
      };
    
      const handleMenuCloseClick = () => {
        const menu = document.querySelector('.rsnp-mnu');
        menu.classList.remove('slidein');
      };
    
      const handleMenuItemClick = (event) => {
        const menuItem = event.currentTarget.parentElement;
        const siblings = Array.from(menuItem.parentNode.children).filter((child) => child !== menuItem);
      
        siblings.forEach((sibling) => {
          const ul = sibling.querySelector('ul');
          if (ul) {
            ul.style.display = 'none';
          }
          sibling.classList.remove('active');
        });
      
        const childUl = menuItem.querySelector('ul');
        if (childUl) {
          if (childUl.style.display === 'block') {
            childUl.style.display = 'none';
          } else {
            childUl.style.display = 'block';
          }
        }
      
        menuItem.classList.toggle('active');
      };
      const handleLinkClick = () => {
      
        window.scrollTo(0, 0);
      };
       
  return (
    <>
     {preloaderVisible && (
 <div id="preloader">            
                <div className="preloader-inner">
                    <i className="preloader-icon thm-clr flaticon-kaaba"></i>
                </div>
            </div>
    )}
            <header className="stick style1 w-100">
                <div className="topbar bg-color1 d-flex flex-wrap justify-content-center w-100">
                    <ul className="topbar-info-list mb-0 list-unstyled d-inline-flex">
                        <li><i className="thm-clr flaticon-sun" style={{color:"yellow"}}></i>Sunrise At: <span className="thm-clr">{sunriseTime}</span></li>
                        <li><i className="thm-clr flaticon-moon" style={{color:"blue"}}></i>Sunset At: <span className="thm-clr">{sunsetTime}</span></li>
                    </ul>
                    <div className="social-links d-inline-flex">
                        <Link to="https://www.facebook.com/JamaMasjidZakariya" title="Facebook" ><i className="fab fa-facebook-f"></i></Link>
                    </div>
                </div>
                
                <div className="logo-menu-wrap d-flex flex-wrap justify-content-between w-100" style={{backgroundColor:"black"}}>
                    <div className="logo position-relative thm-layer opc7 back-blend-multiply thm-bg">
                        <h1 className="mb-0">
                            <Link to="/" title="Home" onClick={handleLinkClick}>
                            <img className="img-fluid" src={Logo2} alt="Logo" srcset={Logo2}/>
                            </Link></h1></div>
                    <nav className="d-flex flex-wrap align-items-center justify-content-end ml-auto">
                        <div className="header-left">
                            <ul className="mb-0 list-unstyled d-inline-flex">
                               
                            <li ><Link to="/" title="" style={{color:"white"}} onClick={handleLinkClick}>Home</Link>
                                    </li>
                                    <li className="menu-item-has-children"><Link to="" title="" style={{color:"white"}}>Spirituality & Bayanat</Link>
                                        
                                        <ul className="mb-0 list-unstyled">
                                            <li><Link  to="/hazrat" title="" onClick={handleLinkClick}>Bayan</Link></li>
                                            <li><Link to="/naat" title="" onClick={handleLinkClick}>Naat</Link></li>
                                            <li><Link to="/seerat" title="" onClick={handleLinkClick}>Books</Link></li>
                                        </ul>
                                    </li>
                                    <li><Link to="/activities" title="" style={{color:"white"}} onClick={handleLinkClick}>Activities</Link></li>
                                    <li><Link to="/services" title="" style={{color:"white"}} onClick={handleLinkClick}>Services</Link></li>
                                    {/* <li className="/blog"><Link to="/blog" title="" style={{color:"white"}}>Blog</Link>
                                    </li> */}
                                    <li className="menu-item-has-children"><Link to="" title="" style={{color:"white"}}>About Us</Link>
                                        <ul className="mb-0 list-unstyled">
                                            <li><Link to="/about" title="" onClick={handleLinkClick}>About Mosque</Link></li>
                                            
                                            <li><Link to="/contact" title="" onClick={handleLinkClick}>Contact Us</Link></li>
                                        </ul>
                                    </li>
                            </ul>
                        </div>
                        <div className="header-right">
                            <Link className="thm-btn thm-bg" to="/login" title="" onClick={handleLinkClick}>Login<span></span><span></span><span></span><span></span></Link>
                        </div>
                    </nav>
                </div>
            </header>
            <div className="sticky-menu">
                <div className="container">
                    <div className="sticky-menu-inner d-flex flex-wrap align-items-center justify-content-between w-200">
                        <div className="logo"><h1 className="mb-0">
                            <Link to="/" title="Home" onClick={handleLinkClick}>
                            <img classNameName="img-fluid" src={Logo2} alt="Logo" srcset={Logo2} /></Link></h1></div>
                        <nav className="d-flex flex-wrap align-items-center justify-content-between">
                            <div className="header-left">
                                <ul className="mb-0 list-unstyled d-inline-flex">
                                <li ><Link to="/" title="" onClick={handleLinkClick}>Home</Link>
                                    </li>
                                    <li className="menu-item-has-children"><Link to="" title="">Spirituality & Bayanat</Link>
                                        
                                        <ul className="mb-0 list-unstyled">
                                            <li><Link  to="/hazrat" title="" onClick={handleLinkClick}>Bayan</Link></li>
                                            <li><Link to="/naat" title="" onClick={handleLinkClick}>Naat</Link></li>
                                            <li><Link to="/seerat" title="" onClick={handleLinkClick}>Books</Link></li>
                                        </ul>
                                    </li>
                                    <li><Link to="/activities" title="" onClick={handleLinkClick}>Activities</Link></li>
                                    <li><Link to="/services" title="" onClick={handleLinkClick}>Services</Link></li>

                                    {/* <li className="/blog"><Link to="/blog" title="">Blog</Link>
                                    </li> */}
                                    <li className="menu-item-has-children"><Link to="" title="">About Us</Link>
                                        <ul className="mb-0 list-unstyled">
                                            <li><Link to="/about" title="" onClick={handleLinkClick}>About Mosque</Link></li>
                                            <li><Link to="/contact" title="" onClick={handleLinkClick}>Contact Us</Link></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="rspn-hdr" style={{backgroundColor:"black"}}>
            <div className="rspn-mdbr" style={{ height: "40px" }}>
      <div className="rspn-scil" style={{ fontSize: "14px", display: "flex", justifyContent: "space-between", alignItems:"center" }}>
        <span style={{marginRight:"20px", color:"peachpuff"}}><i className="fas fa-sun" style={{color:"yellow"}}></i> Sunrise: {sunriseTime}</span>
        <span style={{color:"peachpuff"}} ><i className="fas fa-moon" style={{color:"blue"}}></i> Sunset: {sunsetTime}</span>
      </div>
    </div>
                <div className="lg-mn">
                    <div className="logo"><Link to="/" title="Home"><img src={Logo1} alt="Logo"/></Link></div>
                    <div className="rspn-cnt">
                        <span><i className="thm-clr far fa-envelope"></i><Link to="" title="">infozakariamasjid@gmail.com</Link></span>
                        <span><i className="thm-clr fas fa-phone-alt"></i>+92 321 8259780</span>
                    </div>
                    <span className="rspn-mnu-btn" onClick={handleMenuBtnClick}><i className="fa fa-list-ul"></i></span>
                </div>
                <div className="rsnp-mnu">
                    <span className="rspn-mnu-cls" onClick={handleMenuCloseClick}><i className="fa fa-times"></i></span>
                    <ul className="mb-0 list-unstyled w-100">
                    <li ><Link to="/" title="">Home</Link>
                                    </li>
                                    <li className="menu-item-has-children"><Link to="" title="" onClick={handleMenuItemClick}>Spirituality & Bayanat</Link>
                                        
                                        <ul className="mb-0 list-unstyled">
                                            <li><Link  to="/hazrat" title="">Bayan</Link></li>
                                            <li><Link to="/naat" title="">Naat</Link></li>
                                            <li><Link to="/seerat" title="">Books</Link></li>
                                        </ul>
                                    </li>
                                    <li><Link to="/activities" title="">Activities</Link></li>
                                    <li><Link to="/services" title="">Services</Link></li>

                                    {/* <li className="/blog"><Link to="/blog" title="">Blog</Link>
                                    </li> */}
                                    <li className="menu-item-has-children"><Link to="" title="" onClick={handleMenuItemClick}>About Us</Link>
                                        <ul className="mb-0 list-unstyled">
                                            <li><Link to="/about" title="">About Mosque</Link></li>
                                            <li><Link to="/contact" title="">Contact Us</Link></li>
                                        </ul>
                                    </li>
                    </ul>
                </div>
            </div>
    </>
  )
}

export default NavbarAllFiles
