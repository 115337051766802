import React from "react";
import { Link } from "react-router-dom";
import  { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import NavbarAllFiles from "../NavbarAll/NavbarAllFiles"
const NaatComponent = () => {
  const [preloaderVisible, setPreloaderVisible] = useState(true);
    useEffect(() => {
          try {
            setPreloaderVisible(false); // Set preloader visibility to false
            console.log("Seerat data fetched successfully");
            console.log(preloaderVisible);
          } catch (error) {
            setPreloaderVisible(false);
            console.error('Error fetching Seerat data:', error.message);
          }
      }, [preloaderVisible]);
      
  return (
    <>
      <Helmet>
    <title>Naats</title>
    <meta name="description" content="Naats at Masjid Zakaria aim to provide devotional poetry praising the Prophet Muhammad, expressing love and admiration for him. These naats aim to evoke spiritual emotions, strengthen the connection to the Prophet, and enhance the atmosphere of reverence and love during religious gatherings at the mosque." />
  </Helmet> 
    <NavbarAllFiles />
      <section>
        <div className="w-100 pt-100 pb-120 position-relative">
          <div className="container">
            <div className="sec-title text-center w-100">
              <div className="sec-title-inner d-inline-block">
                <h2 className="mb-0">Naat</h2>
              </div>
            </div>
            <div className="course-wrap3 w-100">
              <div className="row mrg">
                {/* Video 1 */}
                <div className="col-md-12 col-sm-12 col-lg-6">
                  <div className="course-box3 position-relative overflow-hidden w-100">
                    <span className="course-price position-absolute"></span>
                    <div className="video-container">
                      <iframe
                        title="Mera Dil Badal De"
                        width="100%"
                        height="315"
                        src="https://www.youtube.com/embed/q_Mb5O1tqqQ?si=hjYNiG88lhgrE02G"
                        frameBorder="0"
                        allowFullScreen
                      ></iframe>
                    </div>
                    <div className="course-info3 position-absolute w-100">
                      <span className="d-block thm-clr"></span>
                      <h3 className="mb-0">
                        <Link to="https://youtu.be/q_Mb5O1tqqQ?si=jzLYUvPRytyTo8FW" title="Mera Dil Badal De">
                          Mera Dil Badal De | Junaid Jamshed
                        </Link>
                      </h3>
                      <div className="d-inline-flex course-scholar">
                      </div>
                    </div>
                  </div>
                </div>

                {/* Video 2 */}
                <div className="col-md-12 col-sm-12 col-lg-6">
                  <div className="course-box3 position-relative overflow-hidden w-100">
                    <span className="course-price position-absolute"></span>
                    <div className="video-container">
                      <iframe
                        title="Ilahi Teri Chaukhat Per"
                        width="100%"
                        height="315"
                        src="https://www.youtube.com/embed/Yh-VsonQ1mc?si=bxP6gw8siqLTcdxq"
                        frameBorder="0"
                        allowFullScreen
                      ></iframe>
                    </div>
                    <div className="course-info3 position-absolute w-100">
                      <span className="d-block thm-clr"></span>
                      <h3 className="mb-0">
                        <Link to="https://youtu.be/Yh-VsonQ1mc?si=PvyGJKWLxiWVO635" title="Ilahi Teri Chaukhat Per">
                          Ilahi Teri Chaukhat Per | Junaid Jamshed
                        </Link>
                      </h3>
                      <div className="d-inline-flex course-scholar">
                        
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-12 col-sm-12 col-lg-6">
                  <div className="course-box3 position-relative overflow-hidden w-100">
                    <span className="course-price position-absolute"></span>
                    <div className="video-container">
                      <iframe
                        title="Ilahi Teri Chaukhat Per"
                        width="100%"
                        height="315"
                        src="https://www.youtube.com/embed/wllqlcljOsg?si=CnGCo5o02itmsZAt"
                        frameBorder="0"
                        allowFullScreen
                      ></iframe>
                    </div>
                    <div className="course-info3 position-absolute w-100">
                      <span className="d-block thm-clr"></span>
                      <h3 className="mb-0">
                        <Link to="https://youtu.be/wllqlcljOsg?si=l_GWpuoG2ivJj3-o" title="ya man sallaita">
                        Ya Man Salaita Bikullil Anbiya | Anas Younis
                        </Link>
                      </h3>
                      <div className="d-inline-flex course-scholar">
                        {/* ... your existing course scholar info */}
                      </div>
                    </div>
                  </div>
                </div>


                <div className="col-md-12 col-sm-12 col-lg-6">
                  <div className="course-box3 position-relative overflow-hidden w-100">
                    <span className="course-price position-absolute"></span>
                    <div className="video-container">
                      <iframe
                        title="Ilahi Teri Chaukhat Per"
                        width="100%"
                        height="315"
                        src="https://www.youtube.com/embed/iTQ_HeU4wVU?si=ezORmdCU6Cyz2y3_"
                        frameBorder="0"
                        allowFullScreen
                      ></iframe>
                    </div>
                    <div className="course-info3 position-absolute w-100">
                      <span className="d-block thm-clr"></span>
                      <h3 className="mb-0">
                        <Link to="https://youtu.be/iTQ_HeU4wVU?si=5lWxnX2HwGkZ7Yz_" title="Main Phir Loat Aao Ga Meray Illah - Mufti Anas Younus">
                        Main Phir Loat Aao Ga Meray Illah - Mufti Anas Younus
                        </Link>
                      </h3>
                      <div className="d-inline-flex course-scholar">
                        {/* ... your existing course scholar info */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-sm-12 col-lg-6">
                  <div className="course-box3 position-relative overflow-hidden w-100">
                    <span className="course-price position-absolute"></span>
                    <div className="video-container">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/rJi3oDq5e-s?si=kjuCGyX81gSWfbL2" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                    <div className="course-info3 position-absolute w-100">
                      <span className="d-block thm-clr"></span>
                      <h3 className="mb-0">
                        <Link to="https://www.youtube.com/rJi3oDq5e-s?si=kjuCGyX81gSWfbL2" title="Shaz Khan | Allah Se Hota Hai">
                        Shaz Khan | Allah Se Hota Hai
                        </Link>
                      </h3>
                      <div className="d-inline-flex course-scholar">
                        
                      </div>
                    </div>
                  </div>
                </div>
                {/* Add more videos as needed */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NaatComponent;
